<template>
  <div class="contents-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Widget>
      <DataTable
        class="p-datatable-sm"
        :value="getContents"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        :rows="100"
        dataKey="number"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :currentPageReportTemplate="
          $t('show') +
            ' {first} ' +
            $t('to') +
            ' {last} ' +
            $t('of') +
            ' {totalRecords} ' +
            $t('records')
        "
        @row-edit-init="onRowEditInit"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="onRowEditSave"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('contents') }}</h1>
            </div>
            <div class="table-header-right">
              <div class="button-right">
                <router-link class="btn btn-success mb-2 right" :to="{ name: 'ContentCreatePage' }">
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('createContents') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>

        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>
        <Column
          field="number"
          header="#"
          sortable
          :showFilterMenu="false"
          :headerStyle="{ width: '40px' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.number === '_new'">
              {{ $t('_new') }}
            </span>
            <span v-else>
              <router-link
                :to="{
                  name: 'ContentEditPage',
                  params: { contentNumber: slotProps.data.number },
                }"
                >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
              >
            </span>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="name"
          :header="$t('name')"
          sortable
          :styles="{ width: '50%' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps"><span v-html="slotProps.data.name"></span> </template>
          <template #filter>
            <InputText type="text" v-model="filters['name']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="description"
          :header="$t('description')"
          sortable
          :styles="{ width: '50%' }"
          :bodyStyle="{ 'text-align': 'left' }"
        >
          <template #body="slotProps"><span v-html="slotProps.data.description"></span> </template>
          <template #filter>
            <InputText type="text" v-model="filters['description']" class="p-column-filter" />
          </template>
        </Column>
        <Column field="contentAttachments" :header="$t('attachments')" :styles="{ width: '25%' }">
          <template #body="slotProps">
            <div class="attachment-grid">
              <AttachmentList
                :attachments="slotProps.data.contentAttachments"
                :parentId="slotProps.data.id"
                attachmentType="contentAttachments"
              ></AttachmentList>

              <Button
                class="mt-2 p-button-secondary grid-item"
                icon="fa fa-upload"
                @click="onShowUploadDialog(slotProps.data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import AttachmentList from '@/components/AttachmentList';

export default {
  name: 'Contents',
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    Widget,
    InputText,
    AttachmentList,
  },
  data() {
    return {
      loading: true,
      filters: {},
      editingRows: [],
      backupRows: [],
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getContents', 'getContent']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('contents') }];
    },
  },
  methods: {
    ...mapActions([
      'fetchContents',
      'initContent',
      'contentLocalUpdate',
      'updateContent',
      'createContent',
      'contentCleanup',
    ]),
    filterDate(value, filter) {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === 'string' && filter.trim() === '')
      ) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return this.$date(value).indexOf(filter) >= 0 ? true : false;
    },
    onAddContent() {
      this.initContent();
    },
    onRowEditInit(event) {
      if (event.data._id !== '_new') {
        this.backupRows[event.index] = { ...this.getContent(event.data._id) };
      }
    },
    onRowEditCancel(event) {
      if (event.data._id === '_new') {
        this.contentCleanup();
      } else {
        this.contentLocalUpdate(this.backupRows[event.index]);
      }
    },
    async onRowEditSave(event) {
      try {
        if (event.data && event.data._id != '_new') {
          await this.updateContent(event.data);
        } else {
          const content = await this.createContent(event.data);
        }
      } catch (e) {
        // console.log(e);
      }
    },
  },
  async created() {
    await this.fetchContents();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(2),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(2),
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(3),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(3),
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(4),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(4) {
  width: 175px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(5),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(5) {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(6),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(6) {
  width: 90px;
  text-align: right;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(7),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(7) {
  width: 100px;
  text-align: right;
}

.p-editable-column textarea,
.p-editable-column .p-inputtext {
  width: 100%;
}
.contents-component {
  border: 0px solid rebeccapurple;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start; /* align contents in Main Axis */
  align-contents: stretch; /* align contents in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

.fill-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* align contents in Main Axis */
  align-contents: stretch; /* align contents in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}
.flexbox-content-grow {
  flex: 1; /* same as flex: 1 1 auto; */
}
::v-deep .p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
</style>
